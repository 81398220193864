import { createI18n } from "vue-i18n";
import en from "./en-US.json";
import vi from "./vi-VN.json";
var locale = localStorage.getItem("locale") ?? process.env.VUE_APP_LANG;

if (locale === "en") {
  sessionStorage.setItem("Json", JSON.stringify(en));
} else {
  sessionStorage.setItem("Json", JSON.stringify(vi));
}
if (
  localStorage.getItem("locale") == null ||
  (localStorage.getItem("locale") != "en" &&
    localStorage.getItem("locale") != "vi")
) {
  localStorage.setItem("locale", process.env.VUE_APP_LANG);
}

const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    en: en,
    vi: vi,
  },
});

export default i18n;
