export const main_casino_on = {
  en: require("@/assets/img/main/button_casino_on_en.png"),
  vi: require("@/assets/img/main/button_casino_on_vn.png"),
};
export const main_sport_on = {
  en: require("@/assets/img/main/button_sports_on_en.png"),
  vi: require("@/assets/img/main/button_sports_on_vn.png"),
};
export const main_casino_off = {
  en: require("@/assets/img/main/button_casino_off_en.png"),
  vi: require("@/assets/img/main/button_casino_off_vn.png"),
};
export const main_sport_off = {
  en: require("@/assets/img/main/button_sports_off_en.png"),
  vi: require("@/assets/img/main/button_sports_off_vn.png"),
};
export const main_casino = {
  en: require("@/assets/img/main/ic_casino.png"),
  vi: require("@/assets/img/main/vn-ic_casino.png"),
};
export const main_fish = {
  en: require("@/assets/img/main/ic_fish.png"),
  vi: require("@/assets/img/main/vn-ic_fish.png"),
};
export const main_poker = {
  en: require("@/assets/img/main/ic_poker.png"),
  vi: require("@/assets/img/main/vn-ic_poker.png"),
};
export const main_pro = {
  en: require("@/assets/img/main/ic_pro.png"),
  vi: require("@/assets/img/main/vn-ic_pro.png"),
};
export const main_slot = {
  en: require("@/assets/img/main/ic_slot.png"),
  vi: require("@/assets/img/main/vn-ic_slot.png"),
};
export const main_sport = {
  en: require("@/assets/img/main/ic_sport.png"),
  vi: require("@/assets/img/main/vn-ic_sport.png"),
};