<template>
  <div
    class="bg-white position-fixed top-0 start-0 d-flex p-2 align-items-center top_div_width"
    style="padding: 8px 0 0 0; z-index: 99; height: 8vh; gap: 1rem"
  >
    <div class="w-100 d-flex align-items-center" style="gap: 1rem">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <p
          v-if="
            code != null &&
            this.$route.name != 'Home' &&
            this.$route.name != 'igaming' &&
            this.$route.name != 'VIP' &&
            this.$route.name != 'main_sport'
          "
          class="col-6 fw-bold mb-0 ms-3 top_user_text"
          style="font-size: 1.2rem"
          @click="go_home()"
        >
          {{ userid }}
        </p>
        <img
          v-else-if="this.$route.name != 'VIP'"
          src="@/assets/logo.png"
          :title="$t('go_home')"
          class="top_head_img c-pointer"
          @click="go_home()"
        />
        <balanceview
          v-if="
            code != null &&
            (this.$route.name == 'Home' ||
              this.$route.name == 'igaming' ||
              this.$route.name == 'VIP')
          "
          ref="getbalance_home"
        />
        <div class="d-flex align-items-center" v-if="code == null">
          <div
            style="padding: 10px"
            class="c-pointer bg_main_green p-2 rounded-3 me-3"
            @click="this.$router.push('/Login')"
          >
            {{ $t("login1") }}
          </div>
          <div
            class="c-pointer bg_main_purple p-2 rounded-3"
            @click="this.$router.push('/Login/R')"
          >
            {{ $t("register1") }}
          </div>
        </div>
      </div>

      <lang
        v-if="
          code == null ||
          this.$route.name == 'Home' ||
          this.$route.name == 'igaming' ||
          this.$route.name == 'VIP'
        "
      ></lang>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import Lang from "./Home/language.vue";
import balanceview from "./balance.vue";
export default {
  name: "maintop2",
  data() {
    return {
      code: Cookies.get("code"),
      userid: "Gowin",
    };
  },
  components: {
    Lang,
    balanceview,
  },
  computed: {},
  methods: {
    go_home: function () {
      if (this.$route.name == "Home" || this.$route.name == "igaming") {
        window.location.reload();
      } else this.$router.push("/");
    },
    getBalance() {
      this.$refs.getbalance_home.getBalance();
    },
  },
  created() {
    if (this.code != null) {
      this.userid = localStorage.getItem("uid");
    } else {
      this.userid =
        localStorage.getItem("introducer") == null ||
        localStorage.getItem("introducer") == ""
          ? "Gowin"
          : localStorage.getItem("introducer");
    }
  },
  watch: {},
};
</script>
