<template>
  <div>
    <div class="content_img d-flex flex-wrap">
      <!-- <iframe
        :src="getnewurl(index)"
        style="width: 100%; height: 90vh"
        marginwidth="0"
        marginheight="0"
        scrolling="auto"
        frameborder="0"
        align="center"
      ></iframe> -->
      <div v-for="l in list" :key="l" style="width: 50%">
        <img
          :src="l.img"
          style="width: 100%"
          @click="url(l.pkidx)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { index: Number },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    // getnewurl: function (t) {
    //   return (
    //     process.env.VUE_APP_BASEURL +
    //     "/promotions_app.aspx?station=" +
    //     process.env.VUE_APP_STATOIN +
    //     "&id=" +
    //     t
    //   );
    // },
    get_img: function () {
      let param = {
        action: "promotions",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res.data;
      });
    },
    url: function (e) {
      window.open(
        process.env.VUE_APP_BASEURL +
          "promotions_detail.aspx?station=" +
          process.env.VUE_APP_STATOIN +
          "&pkidx=" +
          e
      );
    },
  },
  created() {
    this.get_img();
  },
};
</script>
