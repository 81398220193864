<template>
  <div :class="flag_nav ? 'col-xl-2' : 'col-xl-0'">
    <div
      class="collapse navbar-collapse pe-xl-3 ps-xl-4"
      id="navbarNav"
      :class="flag_nav ? 'show' : ''"
    >
      <div class="d-xl-block d-none" style="transform: translate(10px, 10px)">
        <img
          src="@/assets/img/main/left-arrow.png"
          class="mb-4 nav_arrow"
          style="transform: rotate(180deg)"
          v-show="!flag_nav"
          @click="hide_nav()"
          :title="$t('left_menu_text1')"
        />
      </div>
      <div
        class="justify-content-start nav_left_show mb-xl-3 w-100"
        @click="hide_nav()"
      >
        <button
          class="btn border-0 fw-bold w-100 d-flex align-items-center ps-0"
        >
          <img
            src="@/assets/img/main/left-arrow.png"
            class="nav_arrow mx-2"
            :title="$t('left_menu_text2')"
          /><span>{{ $t("left_menu_text2") }}</span>
        </button>
      </div>
      <ul class="navbar-nav flex-column mb-5">
        <li :class="!flag_nav ? 'd-none' : 'd-block'">
          <div class="nav-link d-flex justify-content-evenly">
            <img
              :src="img_casino"
              @click="go_home(), false_nav()"
              style="flex: 1; max-width: 220px; margin-right: 5px"
            />
            <img
              :src="img_sport"
              @click="click_sport(), false_nav()"
              style="flex: 1; max-width: 220px; margin-right: 0px"
            />
          </div>
        </li>
        <li @click="click_pro(), false_nav()">
          <img src="@/assets/img/left/menu_ic_spgem.png" :title="$t('faq_5')" />
          <a class="nav-link fw-bold">{{ $t("faq_5") }}</a>
        </li>
        <li @click="go_home(), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_1_home.png"
            :title="$t('text_home')"
          />
          <a class="nav-link fw-bold">{{ $t("text_home") }}</a>
        </li>
        <li @click="this.$router.push('/VIP'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_2_member.png"
            :title="$t('left_menu_text3')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text3") }}</a>
        </li>
        <li @click="this.$router.push('/BO/invite'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_3_share.png"
            :title="$t('left_menu_text4')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text4") }}</a>
        </li>
        <li @click="this.$router.push('/ME/wallet'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_4_recharge.png"
            :title="$t('text_recharge')"
          />
          <a class="nav-link fw-bold">{{ $t("text_recharge") }}</a>
        </li>
        <!-- <li @click="click_pro()">
          <img src="@/assets/img/left/menu_ic_spgem.png" :title="$t('faq_5')" />
          <a class="nav-link fw-bold">{{ $t("faq_5") }}</a>
        </li> -->
        <li @click="this.$router.push('/ME/myacc'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_5_myaccount.png"
            :title="$t('myacc')"
          />
          <a class="nav-link fw-bold">{{ $t("myacc") }}</a>
        </li>
        <li @click="this.$router.push('/ME/history'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_6_record.png"
            :title="$t('left_menu_text5')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text5") }}</a>
        </li>
        <li @click="this.$router.push('/ME/activity'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_7_myactivity.png"
            :title="$t('myactivity')"
          />
          <a class="nav-link fw-bold">{{ $t("myactivity") }}</a>
        </li>
        <li @click="this.$router.push('/ME/message'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_8_messages.png"
            :title="$t('left_menu_text6')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text6") }}</a>
        </li>
        <li @click="this.$router.push('/VIP/introduce'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_9_vipbenefits.png"
            :title="$t('left_menu_text7')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text7") }}</a>
        </li>
        <li
          @click="link('service')"
          v-if="
            $store.state.service.qq.qq != '' &&
            $store.state.service.qq.qq != null
          "
        >
          <img
            src="@/assets/img/left/menu_ic_10_customerservice.png"
            :title="$t('left_menu_text8')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text8") }}</a>
        </li>
        <li
          @click="link('telegram')"
          v-if="
            $store.state.service.qq.telegram != '' &&
            $store.state.service.qq.telegram != null
          "
        >
          <img
            src="@/assets/img/left/menu_ic_11_tg.png"
            :title="$t('left_menu_text9')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text9") }}</a>
        </li>
        <!-- <li
          @click="link('fb')"
          v-if="
            $store.state.service.qq.fb != '' &&
            $store.state.service.qq.fb != null
          "
        >
          <img
            src="@/assets/img/left/menu_ic_12_fb.png"
            :title="$t('left_menu_text10')"
          />
          <a class="nav-link fw-bold">{{ $t("left_menu_text10") }}</a>
        </li> -->
        <li @click="this.$store.commit('isann', true), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_billboard.png"
            :title="$t('notice')"
          />
          <a class="nav-link fw-bold">{{ $t("notice") }}</a>
        </li>
        <li @click="this.$router.push('/help/E'), false_nav()">
          <img
            src="@/assets/img/left/menu_ic_partner.png"
            :title="$t('footer_e')"
          />
          <a class="nav-link fw-bold">{{ $t("footer_e") }}</a>
        </li>
        <li
          class="main_activity_li mb-2"
          @click="link('fb')"
          v-if="
            $store.state.service.qq.fb != '' &&
            $store.state.service.qq.fb != null
          "
        >
          <a class="nav-link fw-bold"
            ><img
              src="@/assets/img/left/fb-sidebutton.png"
              class="main_activity"
          /></a>
        </li>
        <!-- <li class="main_activity_li mb-2">
          <a class="nav-link fw-bold"
            ><img
              src="@/assets/img/left/bt_extrabonus.png"
              class="main_activity"
          /></a>
        </li> -->
        <li
          class="main_activity_li mb-2"
          @click="open_wheel_fun(), false_nav()"
        >
          <a class="nav-link fw-bold"
            ><img
              src="@/assets/img/left/bt_luckydraw.gif"
              class="main_activity"
          /></a>
        </li>
        <li
          class="main_activity_li mb-2"
          @click="open_extra_fun(), false_nav()"
        >
          <a class="nav-link fw-bold"
            ><img
              src="@/assets/img/left/bt_extrabonus.png"
              class="main_activity"
          /></a>
        </li>
        <li
          class="main_activity_li"
          @click="$refs.show_sign_up.gift_show_click(), false_nav()"
        >
          <a class="nav-link fw-bold"
            ><img
              src="@/assets/img/left/bt_dailycheckin.png"
              class="main_activity"
          /></a>
        </li>
        <!-- 
        <li class="main_activity_li" @click="share_view()">
          <a class="nav-link fw-bold"
            ><img
              src="@/assets/img/left/button_refer_earn.png"
              class="main_activity"
          /></a>
        </li> -->
        <li v-if="code != null" @click="logout" class="mt-xl-3 ms-xl-2">
          <img
            class="logout_img"
            src="@/assets/img/main/logout.png"
            style="width: 30px; height: 30px"
            :title="$t('logout')"
          />
          <a class="nav-link fw-bold" style="line-height: 15px">{{
            $t("logout")
          }}</a>
        </li>
      </ul>
    </div>
    <newmessage ref="newmess"></newmessage>
    <drawview v-if="flag_draw" @close_wheel_main="close_wheel_fun"></drawview>
    <signupview ref="show_sign_up"></signupview>
    <extraview
      v-if="flag_extra"
      @close_extra_main="close_extra_fun"
    ></extraview>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters, mapActions } from "vuex";
import newmessage from "./new_Message.vue";
import drawview from "./draw.vue";
import signupview from "./sign_up.vue";
import extraview from "./extra.vue";
import {
  main_casino_on,
  main_sport_on,
} from "@/components/Img/img";

export default {
  name: "maintop1",
  data() {
    return {
      code: Cookies.get("code"),
      flag_draw: false,
      flag_extra: false,
      img_casino: JSON.parse(JSON.stringify(main_casino_on))[
        localStorage.getItem("locale")
      ],
      img_sport: JSON.parse(JSON.stringify(main_sport_on))[
        localStorage.getItem("locale")
      ],
    };
  },
  components: { newmessage, drawview, signupview, extraview },
  computed: {
    ...mapGetters(["service", "flag_nav"]),
  },
  methods: {
    ...mapActions(["getService"]),
    logout: function () {
      Cookies.remove("code");
      localStorage.removeItem("uid");
      localStorage.removeItem("utype");
      localStorage.removeItem("first");
      this.$router.go();
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = this.$store.state.service.qq[e];
          break;
      }
      window.open(url);
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    false_nav: function () {
      var isMobile = this.$Utils.isMobile();
      if (isMobile != "pc端") {
        this.$store.commit("flagnav", false);
      }
    },
    go_home: function () {
      if (this.$route.name == "Home" || this.$route.name == "igaming") {
        // window.location.reload();
        this.$emit("click_pro_left", "content18");
      } else {
        this.$router.push("/");
        // this.click_pro();
      }
    },
    getnewmess: function () {
      this.$refs.newmess.msg("30");
    },
    close_wheel_fun: function () {
      this.flag_draw = false;
    },
    close_extra_fun: function () {
      this.flag_extra = false;
    },
    open_wheel_fun: function () {
      if (Cookies.get("code") == null || Cookies.get("code") == "") {
        this.$router.push("Login");
      } else {
        this.flag_draw = true;
      }
    },
    open_extra_fun: function () {
      if (Cookies.get("code") == null || Cookies.get("code") == "") {
        this.$router.push("Login");
      } else {
        this.flag_extra = true;
      }
    },
    click_pro: function () {
      this.$router.push("/pro");
      this.$emit("click_pro_left", "content3");
    },
    newmain_view: function () {
      this.$router.push("/igaming");
      this.$emit("click_pro_left", "igaming");
    },
    click_sport: function () {
      this.$router.push("/main_sport");
    },
    share_view: function () {
      if (this.code == null || this.code == "") this.$router.push("/Login");
      else {
        this.$router.push("/share");
        this.$emit("click_pro_left", "share");
      }
    },
  },
  created() {
    this.getService();
  },
  watch: {},
};
</script>
